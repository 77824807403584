import React, { useState, useEffect, useCallback } from 'react';
import { useScreenShare, useDaily, useLocalParticipant, useDailyEvent } from '@daily-co/daily-react';
import Icon from '@/components/Icon';
import styled from 'styled-components';
import { ToolBar } from '@/views/video-styles';

const Toggle = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid var(--green);
    transition: .2s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: var(--green);
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + span { }

  input:checked + span:before {
    transform: translateX(14px);
  }
`;

const ToggleInput = ({ checked, onChange }) => (
  <Toggle>
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span />
  </Toggle>
);

const ToggleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 40px;
  border-radius: 22px;
  margin-right: 6px;
  transition-duration: 0.2s;
  &:hover{
    transition-duration: 0.2s;
  }
  &.green{
    background-color: rgba(51, 255, 0, 0.1);
    &:hover{
      background-color: rgba(51, 255, 0, 0.3);
    }
  }
  &.red{
    background-color: rgba(246, 49, 53, 0.1);
    &:hover{
      background-color: rgba(246, 49, 53, 0.3);
    }
  }
  img {
    padding: 4px;
    box-sizing: content-box;
  }
`;

const DropdownList = styled.ul`
  position: fixed;
  bottom: 135px;
  min-width: 315px;
  width: auto;
  border-radius: 8px;
  left: calc(50vw - 150px);
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  list-style-type: none;
  margin: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
  padding: 16px;
  .bg{
    position: fixed;
    top: -100vh;
    left: -100vw;
    width: 200vw;
    height: 200vh;
    z-index: -1;
  }
  .divider{
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 8px 0;
  }
`;

const DropdownItem = styled.li`
  cursor: pointer;
  padding: 6px 0;
  padding-left: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.title {
    padding: 6px 8px;
  }
  img{
    margin-left: 24px;
  }
`;

const ToggleButtonGroup = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const EmojiTray = styled.div`
  position: fixed;
  bottom: 120px;
  left: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 8px;
  max-width: 160px;
  flex-wrap: wrap;
  justify-content: center;
 .bg{
    position: fixed;
    top: -100vh;
    left: -100vw;
    width: 200vw;
    height: 200vh;
    z-index: -1;
  }
`;

const EmojiButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.2);
  }
`;

const EmojiTrayComponent = ({ onEmojiSelect, onClose }) => {
  const emojis = ['👏', '👍','👎', '👋', '❤️', '😂', '🚀', '🔥', '🎉'];

  return (
    <EmojiTray>
      <div className="bg" onClick={() => onClose(false)}/>

      {emojis.map((emoji) => (

        <EmojiButton
          key={emoji}
          onClick={() => {
            onEmojiSelect(emoji);
            onClose();
          }}
        >
          {emoji}
        </EmojiButton>
      ))}
    </EmojiTray>
  );
};

function Tray({ endCall, callObject, isHandRaised, onToggleHandRaise, onEmojiReaction, user, call }) {
  const [cameras, setCameras] = useState([]);
  const [microphones, setMicrophones] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [selectedMicrophone, setSelectedMicrophone] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState('');
  const [isCameraDropdownOpen, setIsCameraDropdownOpen] = useState(false);
  const [isMicDropdownOpen, setIsMicDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isNoiseReductionEnabled, setIsNoiseReductionEnabled] = useState(false);
  const [isEmojiTrayOpen, setIsEmojiTrayOpen] = useState(false);

  const { startScreenShare, stopScreenShare, screens } = useScreenShare();
  const daily = useDaily();
  const localParticipant = useLocalParticipant();

  const videoOn = localParticipant?.video;
  const audioOn = localParticipant?.audio;

  const isCallOwner = user.name === call.owner.name;
  const isPresenter = screens[0]?.session_id === callObject.participants().local.session_id;
  const canStopScreenShare = isCallOwner || isPresenter;

  const handleEmojiReaction = useCallback((emoji) => {
    const newReaction = {
      id: Date.now(),
      emoji,
      position: {
        x: Math.random() * 80 + 10,
        y: Math.random() * 80 + 10,
      },
    };
    onEmojiReaction(prev => [...prev, newReaction]);
    daily.sendAppMessage({ action: 'emojiReaction', reaction: newReaction }, '*');
  }, [daily, onEmojiReaction]);

  useDailyEvent(
    'app-message',
    useCallback(
      (evt) => {
        if (evt.data.action === 'emojiReaction') {
          onEmojiReaction(prev => [...prev, evt.data.reaction]);
        }
      },
      [onEmojiReaction]
    )
  );

  useEffect(() => {
    async function getDevices() {
      if (typeof window !== 'undefined') {
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
          const devices = await navigator.mediaDevices.enumerateDevices();
          const cams = devices.filter(device => device.kind === 'videoinput');
          const mics = devices.filter(device => device.kind === 'audioinput');
          const spks = devices.filter(device => device.kind === 'audiooutput');

          setCameras(cams);
          setMicrophones(mics);
          setSpeakers(spks);

          if (cams.length > 0) setSelectedCamera(cams[0].deviceId);
          if (mics.length > 0) setSelectedMicrophone(mics[0].deviceId);
          if (spks.length > 0) setSelectedSpeaker(spks[0].deviceId);

          setIsLoading(false);
        } catch (error) {
          console.error('Error accessing media devices:', error);
          setIsLoading(false);
        }
      }
    }

    getDevices();
  }, []);

  const toggleVideo = () => {
    callObject.setLocalVideo(!videoOn);
  };

  const toggleAudio = () => {
    callObject.setLocalAudio(!audioOn);
  };

  const handleCameraChange = (deviceId) => {
    setSelectedCamera(deviceId);
    callObject.setInputDevicesAsync({ videoDeviceId: deviceId });
    setIsCameraDropdownOpen(false);
  };

  const handleMicrophoneChange = (deviceId) => {
    setSelectedMicrophone(deviceId);
    callObject.setInputDevicesAsync({ audioDeviceId: deviceId });
    setIsMicDropdownOpen(false);
  };

  const handleSpeakerChange = (deviceId) => {
    setSelectedSpeaker(deviceId);
    callObject.setOutputDeviceAsync({ outputDeviceId: deviceId });
    setIsMicDropdownOpen(false);
  };

  const stopAllScreenShares = useCallback(() => {
    if (!daily) return;
    daily.sendAppMessage({ action: 'stop-screen-share' }, '*');
    stopScreenShare();
  }, [daily, stopScreenShare]);

  useDailyEvent('app-message', useCallback((evt) => {
    if (evt.data.action === 'stop-screen-share') {
      stopScreenShare();
    }
  }, [stopScreenShare]));

  const toggleScreenShare = () => {
    if (screens.length > 0) {
      if (canStopScreenShare) {
        stopAllScreenShares();
      } else {
        console.log("You don't have permission to stop the screen share.");
      }
    } else {
      if (screens.length === 0) {
        startScreenShare();
      } else {
        console.log("Another participant is already sharing their screen.");
      }
    }
  };

  const toggleNoiseReduction = () => {
    setIsNoiseReductionEnabled(!isNoiseReductionEnabled);

    callObject.updateInputSettings({
      audio: {
        processor: {
          type: 'noise-cancellation',
        },
      },
    });
  };

  const hangup = () => {
    endCall()
  };

  if (isLoading) {
    return <div>Loading devices...</div>;
  }

  return (
    <ToolBar>
      <div>
        <button onClick={toggleScreenShare} disabled={!canStopScreenShare && screens.length > 0}>
          <Icon name={screens.length > 0 ? "close" : "screenshare"} />
        </button>
        <button onClick={onToggleHandRaise} style={isHandRaised ? {"backgroundColor": 'rgba(255, 0, 0, 0.5)'} : {}}>
          <Icon name="hand" />
        </button>

          <ToggleButton onClick={() => setIsEmojiTrayOpen(!isEmojiTrayOpen)}>
            <Icon name="emoji" />
          </ToggleButton>
          {isEmojiTrayOpen && (
            <EmojiTrayComponent
              onEmojiSelect={handleEmojiReaction}
              onClose={() => setIsEmojiTrayOpen(false)}
            />
          )}

        { isSettingsOpen && <button onClick={() => setIsSettingsOpen(!isSettingsOpen)}> <Icon name="dotdotdot" /> </button> }
      </div>

      <div>
        <ToggleButtonGroup>
          <ToggleButton className={videoOn ? 'green' : 'red'}>
            <div onClick={toggleVideo}>
              <Icon name={videoOn ? 'camera-on' : 'camera-off'} />
            </div>
            <div onClick={() => setIsCameraDropdownOpen(!isCameraDropdownOpen)}>
              <Icon name={videoOn ? 'carret-down-green' : 'carret-down-red'} size={16} />
            </div>
          </ToggleButton>
          { isCameraDropdownOpen &&
            <DropdownList>
              <div className="bg" onClick={() => setIsCameraDropdownOpen(false)}/>
              <DropdownItem className="title">Select Camera</DropdownItem>
              {cameras.map((camera) => (
                <DropdownItem
                  key={camera.deviceId}
                  onClick={() => handleCameraChange(camera.deviceId)}
                >
                  {camera.label || `Camera ${camera.deviceId.substr(0, 5)}`}
                  {selectedCamera === camera.deviceId && <Icon name="selected" color="green" size={16} />}
                </DropdownItem>
              ))}
            </DropdownList>
          }
        </ToggleButtonGroup>

        <ToggleButtonGroup>
          <ToggleButton className={audioOn ? 'green' : 'red'}>
            <div onClick={toggleAudio}>
              <Icon name={audioOn ? 'mic-on' : 'mic-off'} />
            </div>
            <div onClick={() => setIsMicDropdownOpen(!isMicDropdownOpen)}>
              <Icon name={audioOn ? 'carret-down-green' : 'carret-down-red'} size={16} />
            </div>
          </ToggleButton>
          { isMicDropdownOpen &&
            <DropdownList>
              <div className="bg" onClick={() => setIsMicDropdownOpen(false)}/>
              <DropdownItem className="title">Select Microphone</DropdownItem>
              {microphones.map((mic) => (
                <DropdownItem
                  key={mic.deviceId}
                  onClick={() => handleMicrophoneChange(mic.deviceId)}
                >
                  {mic.label || `Microphone ${mic.deviceId.substr(0, 5)}`}
                  {selectedMicrophone === mic.deviceId && <Icon name="selected" color="green" size={16} />}
                </DropdownItem>
              ))}

              <div className="divider" />

              <DropdownItem className="title">Select Speaker</DropdownItem>
              {speakers.map((speaker) => (
                <DropdownItem
                  key={speaker.deviceId}
                  onClick={() => handleSpeakerChange(speaker.deviceId)}
                >
                  {speaker.label || `Speaker ${speaker.deviceId.substr(0, 5)}`}
                  {selectedSpeaker === speaker.deviceId && <Icon name="selected" color="green" size={16} />}
                </DropdownItem>
              ))}

              <div className="divider" />

              <DropdownItem className="title"> Reduce mic noise
                <ToggleInput
                  checked={isNoiseReductionEnabled}
                  onChange={toggleNoiseReduction}
                />
              </DropdownItem>
            </DropdownList>
          }
        </ToggleButtonGroup>
      </div>
      <div>
        <button onClick={() => { hangup() }}>
          <Icon name={window.location.href.includes('breakout') ? "return" : "hangup" }/>
        </button>
      </div>

    </ToolBar>
  );
}

export default Tray;
