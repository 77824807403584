//Warning: Make sure this is a child component to VeltProvider 
//and not within the same file where VeltProvider is placed.

// 1) Get the Velt Client
import { useVeltClient } from '@veltdev/react';
import { useEffect } from 'react';


export default function YourAuthComponent() {

  const userService = () => {
    return {
      uid:"123",
      displayName:"Bob",
      email:"bob@gmail.com",
      photoURL:'https://i.pravatar.cc/300',
      color: "#f9c71f", // Use valid Hex code value. Used in the background color of the user's avatar.
      textColor: "#000000", // Use valid Hex code value. Used in the text color of the user's intial when photoUrl is not present.
      organizationId: "7"
    }
  }

  let yourAuthenticatedUser = userService()

  const { client } = useVeltClient();

  // 2) Create a useEffect hook
  useEffect(() => {
    const initVelt = async () => {
      if (client && yourAuthenticatedUser) {

        // 3) Fetch the relevant user info from yourAuthenticatedUser
        const { uid, displayName, email, photoURL, organizationId } = yourAuthenticatedUser;

        // Create the Velt user object
        const user = {
          userId: uid,
          name: displayName,
          email: email,
          photoUrl: photoURL,
          color: "#f9c71f", // Use valid Hex code value. Used in the background color of the user's avatar.
          textColor: "#00000", // Use valid Hex code value. Used in the text color of the user's intial when photoUrl is not present.
          organizationId: organizationId // this is the organization id the user belongs to
        };

        //4) Pass the user object to the SDK
        await client.identify(user)
      }
    }
    initVelt().catch(console.error)
  }, [client, yourAuthenticatedUser]);

  return (
    <div>
    
    </div>
  );
}