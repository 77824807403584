import React, { useState, useEffect, useRef } from 'react';
import { getRequest, postRequest, uploadRequest } from '@/utils.js';
import Loader from '@/components/CoverLoader.js';
import { Button } from './styles';
import styled from 'styled-components';
import Poppy from '@/public/poppy.png';

const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Slides = styled.div`
  width: 75vw;
  height: 60vh;
  border-radius: 16px;
  background-color: var(--grey);
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  pointer-events: none;
`;

const Slide = styled.div`
  min-width: 100%;
  max-width: 100%;
  max-width: 100%;
  min-height: 100%;
  pointer-events: auto;
  overflow: hidden;
  postion: relative;
  .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
  }

  div:not(.loader){
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--grey);

    padding: 10%;
      z-index: 2;
    img{
      height: 60%;
      width: auto;
      margin-bottom: 10vh;
    }


  }
  video{
    position: relative;
    padding: 2%;
    width: 100%;
    border-radius: 16px;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 75vw;
  padding-top: 5vh;
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
    margin-left: 72px;
`;

const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--light-grey);
  margin: 0 4px;
  &.active {
    background-color: var(--hilight);
  }
`;

const Modal = styled.div`
background-color: var(--light-grey);
border-radius: 16px;
width: 500px;
height: auto;
padding: 32px;

h2{
  font-weight: 600px;
  font-size: 22px;
}

button{
  margin-top: 32px;
}
.full{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.2;
  border-radius: 16px;
  object-fit: cover;
}
small{
  font-size: 11px;
  display: block;
  margin-bottom: 24px;
}
`

const Label = styled.label`
  display: block;
  margin-top: 28px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
`;

const InputField = styled.input`
  width: 100%;
  padding: 18px;
  margin-bottom: 20px;
  border-radius: 16px;
  border: 1px solid #4b4b4d;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
`;

const FileUpload = styled.div`
  width: 80px;
  border: 1px dashed #4b4b4d;
  border-radius: 16px;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  color: white;
  p{
    padding: 16px 24px;
    color: white;
    margin: 0;
  }

  .icon{
    width: 32px;
    max-width: 32px;
    height: 32px;
    z-index: 4;
    position: relative;
  }
  span{
  font-size: 11px;
    position: absolute;
    left: 0;
    bottom: -24px;
    opacity: 0.2;
  }
  &:hover {
    border-color: #8e8e93;
    cursor: pointer;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

`;

const User = () => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' });
  const [loading, setLoading] = useState(true);
  const [slideMode, setSlideMode] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [file, setFile] = useState(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 2;
  const slidesRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
        alert('File size exceeds 10MB. Please choose a smaller file.');
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    const { id, filename } = await uploadRequest('/upload', formData);
    setFile({id: id, filename: filename})
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user');
      setUser(data);

      if(!data){ return }

      setTimeout(() => {
        setLoading(false)
      }, 1500);

      setName(data.name);
      setEmail(data.email);

      if (data.completed > -2) {
        window.location.href = '/';
      }
    };
    getUser();
  }, []);

  const saveProfile = async () => {
    const cohort = window.localStorage.getItem('cohort')
    await postRequest(`/edit_user/${user.id}`, JSON.stringify({
      name: name,
      email: email,
      picture: file ? `${process.env.REACT_APP_API_URL}/profile/${file.id}` : user.picture,
      cohort: parseInt(cohort || '4'),
      program: window.localStorage.getItem('program') || 'alpha-x',
    }));
    window.confetti.add()
    if(window.localStorage.getItem('program') === 'alpha-x'){
      handleNext(true)
    }else{
      setSlideMode(true)
    }
  }

  const handleNext = async (skip = false) => {
    if(currentSlide === totalSlides - 1 || skip) {
      await getRequest(`/complete?completed=-1`)
      window.location.href = '/';
      return;
    }

    if(currentSlide === totalSlides - 2) {
      document.querySelector('video').play();
    }

    const newIndex = (currentSlide + 1) % totalSlides;
    setCurrentSlide(newIndex);
    const newScrollPosition = newIndex * slidesRef.current.clientWidth;
    slidesRef.current.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  };

 const handleName = (e) => { setName(e.target.value); };
  const handleEmail = (e) => { setEmail(e.target.value); };

  return (
    <div className="max-view">
      {
        !slideMode && !loading &&
        <Carousel>
          <Modal>
            <h2>Setup your profile</h2>
            <Label> Avatar </Label>
            <FileUpload>
              <input
                type="file"
                id="fileUpload"
                accept="image/*"
                onChange={handleFileChange}
              />

              {(user || file) && <img className="full" src={file ? `${process.env.REACT_APP_API_URL}/profile/${file.id}` : user.picture }/>}
              <img className="icon" src="https://storage.googleapis.com/prequel-public/Frame%20446.png" alt="upload"/>
            </FileUpload>


            <Label htmlFor="name">Full Name</Label>
            <InputField
              type="text"
              id="name"
              value={name}
              onChange={handleName}
              placeholder="Enter name"
            />

            <Label htmlFor="email">Email</Label>
            <InputField
              type="email"
              id="email"
              value={email}
              onChange={handleEmail}
              placeholder="Enter email"
            />
            <small>use the same email you used to register at Prequel</small>

            <Button className="main" onClick={() => saveProfile()}>Save profile</Button>
          </Modal>
        </Carousel>
      }

      { slideMode && !loading &&
        <Carousel>
          <Slides ref={slidesRef}>
            <Slide>
              <div>
                <img src={Poppy} />
                <h1> Hi, {user.name.split(' ')[0]}! Welcome to PrequelHQ 🎉 </h1>
                <span>Build your startup. Gain skills.</span>
              </div>
            </Slide>

            <Slide>
              <div className="loader"></div>
              <video autoPlay={false} src="https://storage.googleapis.com/prequel-public/betacamp-onboarding.mp4" controls={true} id='video'>
                <source src="https://storage.googleapis.com/prequel-public/betacamp-onboarding.mp4" type="video/mp4" />
              </video>
            </Slide>
          </Slides>

          <Controls>
            <div></div>

            <Pagination>
              {[...Array(totalSlides)].map((_, index) => (
                <Dot key={index} className={currentSlide === index ? 'active' : ''}></Dot>
              ))}
            </Pagination>

            <Button className="main" onClick={handleNext}>{currentSlide === totalSlides - 1 ? 'Jump In!' : 'Next'}</Button>
          </Controls>
        </Carousel>
      }

      {loading && <Loader/> }
    </div>
  );
};

export default User;

