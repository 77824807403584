import React, { useState,  useRef } from 'react';
import styled from 'styled-components';

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const OptionsList = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid var(--admin);
  background-color: var(--admin);
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  top: calc(-100% - 24px);
  z-index: 1000;
`;

const OptionItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;


const UserSelect = ({ options, onSelect, placeholder }) => {
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchRef = useRef(null);

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (option) => {
    onSelect(option);
    setSearch('');
    setIsFocused(false);
  };

  const handleBlur = (e) => {
    if (!searchRef.current.contains(e.relatedTarget)) {
      setTimeout(() => setIsFocused(false), 400);
    }
  };

  return (
    <SelectContainer ref={searchRef} onBlur={handleBlur}>
      <SearchInput
        type="text"
        placeholder={placeholder}
        value={search}
        onFocus={() => setIsFocused(true)}
        onChange={(e) => setSearch(e.target.value)}
      />
      {isFocused && (
        <OptionsList>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <OptionItem key={option.id} onClick={() => handleSelect(option)}>
                {option.name}
              </OptionItem>
            ))
          ) : (
            <OptionItem>No results found</OptionItem>
          )}
        </OptionsList>
      )}
    </SelectContainer>
  );
};

export default UserSelect;
