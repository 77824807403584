import React from 'react';
import styled, { css } from 'styled-components';

const sizeStyles = {
  small: css`
    font-size: 12px;
    padding: 6px 12px;
  `,
  medium: css`
    font-size: 14px;
    padding: 8px 16px;
  `,
  large: css`
    font-size: 16px;
    padding: 10px 20px;
  `,
};

const typeStyles = {
  default: css`
    background-color: var(--dark-grey);
    color: var(--base-white);
  `,
  primary: css`
    background-color: var(--base-white);
    color: var(--base-black);
  `,
  tertiary: css`
    background-color: var(--base-black);
    color: var(--mint-1);
  `,
  text: css`
    background-color: transparent;
    color: var(--mint-1);
  `,
};

const stateStyles = {
  default: css`
    &:hover {
      background-color: var(--light-grey);
    }
    &:active {
      background-color: var(--light-grey);
    }
  `,
  hover: css`
    background-color: var(--light-grey);
  `,
};

const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  ${({ size }) => sizeStyles[size]}
  ${({ type }) => typeStyles[type]}
  ${({ state }) => stateStyles[state]}

  &:disabled {
    background-color: var(--light-grey);
    color: var(--dark-grey);
    cursor: not-allowed;
  }
`;

const Button = ({ size, type, state, children, ...props }) => {
  return (
    <StyledButton size={size} type={type} state={state} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
