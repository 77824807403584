import React from 'react';
import styled, { keyframes } from 'styled-components';

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const GradientParagraph = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;
  background: linear-gradient(90deg, #ffffff, #929292, #929292);
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${gradientShift} 2s ease infinite;
`;

const GradientText = ({ text }) => {
  return <GradientParagraph>{text}</GradientParagraph>;
};

export default GradientText;