import React, { useState, useEffect } from 'react';
import  { getRequest } from './../../utils.js';
import  { AdminView } from './../styles.js';
import AdminLogo from './../../public/admin_logo.svg';

import Admin1 from './../../public/admin1.png';
import Admin2 from './../../public/admin2.png';
import AdminCopy from './../../public/admin_login.svg';

import styled from 'styled-components';

const Logo  = styled.img`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 32px;
`
const Deccoration  = styled.div`
  position: relative;
  background-color: #252206;
  width: 50vw;
  height: 100vh;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .copy{

  }

  .round{
    position: absolute;
    width: 400px;
    height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
  .top{
    top: -20%;
    right: -15%;j
  }
  .bottom{
    bottom: -20%;
    left: -15%;
  }
`

const Copy  = styled.div`
width: 50vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
p{
  color: black;
}
`
const Admin = () => {
  useEffect(() => {
    setTimeout(() => { window.location.href = '/admin/teams' }, 1500)
  }, [])

  return (
    <AdminView>
      <Logo className="logo" src={AdminLogo} />

      <Deccoration>
        <img className="round top" src={Admin2} alt=""/>
        <img className="copy" src={AdminCopy} alt=""/>
        <img className="round bottom" src={Admin1} alt=""/>
      </Deccoration>

      <Copy>
        <p> You will be redirected to Log in </p>
      </Copy>
    </AdminView>
  );
};

export default Admin;
