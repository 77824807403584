import React, { useState, useEffect, useCallback, useRef } from 'react';
import  { postRequest, uploadRequest, getRequest, PROGRAMS } from '@/utils.js';
import  { AdminView, AdminHeader, AdminSidebarForm, Button } from '@/views/styles.js';
import Nav from '@/components/admin/Nav.js';
import Icon from '@/components/Icon.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { marked } from 'marked';
import TurndownService from 'turndown';
import styled from 'styled-components';

const turndownService = new TurndownService();

const MarkdownEditor = ({ value, onChange }) => {
  const [editorHtml, setEditorHtml] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    const html = marked(value);
    setEditorHtml(html);
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    setEditorHtml(content);
    onChange(content);
  };

  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const { id } = await uploadRequest('/upload', formData);
        const range = editorRef.current.getEditor().getSelection();
        editorRef.current.getEditor().insertEmbed(range.index, 'image', `${process.env.REACT_APP_API_URL}/file/${id}`);
      }
    };
    input.click();
  }, [editorRef]);

  const modules = {
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        'image': handleImageUpload
      }
    }
  };

  return (
    <ReactQuill
      style={{ minHeight: '500px' }}
      ref={editorRef}
      theme="snow"
      value={editorHtml}
      onChange={handleChange}
      modules={modules}
    />
  );
};


const Content = styled.div`
  height: 100vh;
  flex-grow: 1;
  overflow-y: scroll;
`

const Page = styled.div`
  display: flex;
  flex-direction: row;
`

const ResourceBlock = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid var(--admin);
  border-radius: 8px;
  margin: 32px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  h2{
    padding: 12px;
    font-weight: 600;
    border-bottom: 1px solid var(--admin);
  }
`

const ResourceGroup = styled.div`
  display: flex;
  flex-direction: column;
  h2{
    padding: 16px;
    font-weight: 600;
    border-bottom: 1px solid var(--admin);
    border-top: 1px solid var(--admin);
  }

  &:first-child{
    h2{
      border-top: none;
    }
  }
  &:last-child{ border-bottom: none }
`

const Resource = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--admin);
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover{
    background-color: #F1F5F9;
  }

  h3{
    font-weight: 500;
    margin-right: 16px;
    min-width: 350px;
    max-width: 350px;
  }
  p{
    color: black;
    width: 125px;
  }
  >div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  img{
    border-radius: 4px;
    width: 75px;
    height: 50px;
    object-fit: cover;
    margin-right: 16px;
  }
  &:last-child{ border-bottom: none }
`

const Resources = () => {
  const [resources, setResources] = useState([])
  const [editing, setEditing] = useState(null)
  const [user, setUser] = useState({permissions: []})

  const loadResources = async () => {
    const u = await getRequest('/user')
    setUser(u)

    const data = await getRequest('/admin/resources')
    const types = [...new Set(data.map(resource => resource.type))];
    const organizedResources = types.map(type => {
      return {
        type,
        resources: data.filter(resource => resource.type === type),
      };
    });
    setResources(organizedResources);
  }

  useEffect(() => {
    loadResources()
  }, [])

  const deleteResource = async (id) => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      await getRequest('/admin/resource/delete?id=' + id);
      setEditing(null);
      loadResources();
    }
  };

  const togglePublish = async (id) => {
    if (window.confirm("Are you sure you want to publish this resource? Please save any edit before publishing.")) {
      await getRequest('/admin/resource/publish?id=' + id);
      loadResources();
      setEditing({...editing, published: !editing.published});
    }
  };

  const save = async (e) => {
    if (window.confirm("Are you sure you want to save these changes?")) {
      await postRequest('/admin/resources', JSON.stringify(e));
      loadResources();
    }
  };

  const createResource = async (e) => {
    await postRequest('/admin/resources/add', JSON.stringify(e));
    loadResources();
    setEditing(null);
  };

  const addMode = () => {
    setEditing(null);
    setTimeout(() => { setEditing({}) }, 300)
  }

  return (
    <AdminView>
      <Nav />

      { (!user || !user.permissions.includes('pd')) && <div className="max-center">
        <Icon name="close" size={50}></Icon>
        <p> You do not have permission to view this page. </p>
      </div>}

      { user && user.permissions.includes('pd') &&
        <Content>
          <AdminHeader>
            <h1>Resources</h1>
            <Button className="admin primary" onClick={() => { addMode() }}> <Icon name="add" size={10}></Icon> Add Resource</Button>
          </AdminHeader>

          <Page>
            <ResourceBlock>
              {resources.map((resourceGroup) => {
                return (
                  <ResourceGroup key={resourceGroup.type}>
                    <h2>{resourceGroup.type}</h2>
                    { resourceGroup.resources.map((resource, i) => {
                      return (
                        <Resource key={i + '-' + resourceGroup.id} onClick={() => {setEditing(resource)}}>
                          <div>
                            <img src={resource.img} alt=""/>
                            <h3>{resource.name}</h3>
                            <p>{resource.detail}</p>
                            <p>{resource.published ? 'Published' : 'Draft' }</p>
                          </div>

                        </Resource>
                      )
                    })}
                  </ResourceGroup>
                )
              })}
            </ResourceBlock>
          </Page>
        </Content>
      }

      {editing !== null &&
        <AdminSidebarForm>
          <AdminHeader className="sec-header">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div onClick={() => { setEditing(null)} }>
                <Icon name="close" size={8} ></Icon>
              </div>
              <h2>Edit resource</h2>
            </div>
            <div>
              {!editing._id &&
                <Button className="admin primary" onClick={() => { createResource(editing) }}> Create Resouce </Button>
              }
              {editing._id &&
                <>
                  <Button className="admin danger" onClick={() => { deleteResource(editing.id) }}> Delete </Button>
                  <Button className="admin secondary" onClick={() => { save(editing) } }> Save </Button>
                  <Button className="admin primary" onClick={() => { togglePublish(editing.id) } }> {editing.published ? 'Unpublish' : 'Publish' } </Button>
                </>
              }
            </div>
          </AdminHeader>

          <form>
            <div className="row">
              <div className="group">
                <label htmlFor="name">Title</label>
                <input
                  type="text" name="name" id="name" placeholder="Resource Name"
                  value={editing.name}
                  onChange={(e) => {setEditing({...editing, name: e.target.value}) }}/>
              </div>

              <div className="group">
                <label htmlFor="slug">URL Slug</label>
                <input type="text" name="slug" id="slug" placeholder="resource-url-name"
                  value={editing.id}
                  onChange={(e) => {setEditing({...editing, id: e.target.value}) }}
                />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="program">Program</label>
                <select value={editing.cohort} onChange={(e) => { setEditing({...editing, cohort: e.target.value}) }}>
                  {!editing._id && <option value="" selected default disabled>select program</option> }
                  {PROGRAMS.map(program => <option value={program} key={program}>{program}</option>)}
                </select>
              </div>

              <div className="group">
                <label htmlFor="group">Group</label>

                { (resources.map(group => group.type).includes(editing.type) || !editing.type) &&
                  <select value={editing.type} onChange={(e) => { setEditing({...editing, type: e.target.value}) }}>
                    {!editing._id && <option value="" selected default disabled>select group</option> }
                    {resources.map(group => <option value={group.type} key={group.type}>{group.type}</option>)}
                    <option value="enter new group name">+ Add New Group</option>
                  </select>
                }

                { (!resources.map(group => group.type).includes(editing.type) && editing.type) &&
                  <input type="text" name="group" id="group" placeholder="group"
                    value={editing.type}
                    onChange={(e) => {setEditing({...editing, type: e.target.value}) }}
                  />
                }

              </div>

              <div className="group">
                <label htmlFor="label">Label</label>
                <input type="text" name="label" id="label" placeholder="Resource Subtitle"
                  value={editing.detail}
                  onChange={(e) => {setEditing({...editing, detail: e.target.value}) }} />
              </div>
            </div>


            <div className="row">
              <img src={editing.img || 'https://via.placeholder.com/150' } alt="img" />
              <div className="group img-group">
                <label htmlFor="img">Image Link</label>
                <input type="text" name="img" id="img" placeholder="upload image" value={editing.img} onChange={(e) => {setEditing({...editing, img: e.target.value}) }} />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="context">Context</label>
                <textarea name="context" id="context" placeholder="LLM prompt" value={editing.context} onChange={(e) => {setEditing({...editing, context: e.target.value}) }}
                />
              </div>
            </div>

            <div className="group">
              <label>Course</label>
              <div>
                <input type="radio" name="course" value="none" checked={!editing.course || !editing.course.type} onChange={() => setEditing(null)} /> None
                <input type="radio" name="course" value="video" checked={editing.course && editing.course.type === 'video'} onChange={() => setEditing({ ...editing, course: {...editing.course, type: 'video'}})} /> Video
                <input type="radio" name="course" value="slides" checked={editing.course && editing.course.type === 'slides'} onChange={() => setEditing({ ...editing, course: {...editing.course, type: 'slides'}})} /> Slides
                <input type="radio" name="course" value="img" checked={editing.course && editing.course.type === 'img'} onChange={() => setEditing({ ...editing, course: {...editing.course, type: 'img'}})} /> Image
              </div>
              {editing.course && (
                <div className="row">
                  {editing.course.type === 'img' && (
                    <img src={editing.course.asset || 'https://via.placeholder.com/150'} alt="img" />
                  )}
                  <div className="group">
                    <label htmlFor="asset">Configure {editing.course.type} Asset</label>
                    <input type="text" name="asset" id="asset" placeholder={`url to ${editing.course.type}`} value={editing.course.asset || ''} onChange={(e) => setEditing({ ...editing, course: {...editing.course, asset: e.target.value} })} />
                  </div>
                  {editing.course.type === 'video' && (
                    <div className="group">
                      <label htmlFor="len">Length</label>
                      <input type="text" name="len" id="len" placeholder="3:02" value={editing.course.len || ''} onChange={(e) => setEditing({ ...editing, course: {...editing.course, len: e.target.value} })} />
                    </div>
                  )}
                  {editing.course.type === 'slides' && (
                    <div className="group">
                      <label htmlFor="len">Page Range</label>
                      <input type="text" name="len" id="len" placeholder="1-3" value={editing.course.len || ''} onChange={(e) => setEditing({ ...editing, course: {...editing.course, len: e.target.value} })} />
                    </div>
                  )}
                </div>
              )}
              {editing.course && editing.course.type === 'video' && (
                <div className="row">
                  <img src={editing.course.preview || 'https://via.placeholder.com/150'} alt="img" />
                  <div className="group img-group">
                    <label htmlFor="preview">Video Thumbnail</label>
                    <input type="text" name="preview" id="preview" placeholder="upload image" value={editing.course.preview || ''} onChange={(e) => setEditing({ ...editing, course: {...editing.course, preview: e.target.value} })} />
                  </div>
                </div>
              )}
            </div>


            <div className="row">
              <div className="group" data-color-mode="light" >
                <label htmlFor="body">Body</label>
                <MarkdownEditor
                  value={editing.body || ''}
                  onChange={(newBody) => setEditing({ ...editing, newBody: turndownService.turndown(newBody) })}
                />
              </div>
            </div>
          </form>
        </AdminSidebarForm>
      }
    </AdminView>
  );
};

export default Resources;
