import React, { useState, useEffect } from 'react';
import Icon from '@/components/Icon';
import styled from 'styled-components';

const StyledSidebarForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--admin);
  border-radius: 8px;
  max-width: 387px;
  min-width: 387px;
  overflow-y: scroll;
  height: 100vh;

  .step-indicator {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #0F172A;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background: #E2E8F0;
    border-radius: 100px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    row-gap: 16px;
    margin-top: 16px;
    
    .complete-label, .pending-label {
      color: white;
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;
      border: none;
    }
    .complete-label {
      background-color: green;
    }
    .pending-label {
      background-color: red;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    row-gap: 32px;
    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      
      label {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
      }
      
      input, textarea {
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        padding: 16px;
        border: 1px solid #CBD5E1;
      }
      
      textarea {
        min-height: 150px;
      }
    }
  }
`;
const StyledButton = styled.button`
  border-radius: 6px;
  border: 1px solid #E2E8F0;
  background: #FFF;
  padding: 8px 16px;
  height: 40px;
  color: #EF4444;
  font-weight: 600;
`
const StyledFlex = styled.div`
    display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: center;
`;
const StyledCloseIcon = styled.div`
   cursor: pointer;
   padding: 8px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 6px; 
   border: 1px solid #E2E8F0;
   background: #FFF;
   display: flex;
`
const StyledHeading = styled.h3`
    color: #000;
    font-size: 24px;
    font-weight: 600;
`
const TaskGoalForm = ({ onClose, data, name, onSubmit, onDelete, customContent }) => {

  const [formData, setFormData] = useState({
    content: data?.stage_name || '',
    prompt: data?.ais_prompt || '',
    status: data?.status || '',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState({ content: '', prompt: '' });
  const [touched, setTouched] = useState({ content: false, prompt: false });

  useEffect(() => {
    const newErrors = { content: '', prompt: '' };

    if (formData.content.length === 0) {
      newErrors.content = 'Content cannot be empty';
    } else if (formData.content.length > 250) {
      newErrors.content = 'Content must be 250 characters or less';
    }

    if (formData.prompt.length === 0) {
      newErrors.prompt = 'Prompt cannot be empty';
    }

    setErrors(newErrors);
    setIsFormValid(!newErrors.content && !newErrors.prompt);
  }, [formData]);

  useEffect(() => {
    setFormData({
      content: data?.stage_name || '',
      prompt: data?.ais_prompt || '',
      status: data?.status || ''
    })
  }, [data]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      onSubmit(formData);
    }
  };

  return (
    <StyledSidebarForm>
      <StyledFlex justifyContent={data?.id ? "space-between" : "flex-end"}>
        {!customContent && data?.id && <div className="step-indicator">{data?.id}</div>}
        <StyledCloseIcon onClick={() => { onClose(false) }}>
          <Icon name="close-black" size={16} />
        </StyledCloseIcon>
      </StyledFlex>
      {customContent ?
        <>
          <div className="header">
            <StyledHeading>{name}</StyledHeading>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              {customContent}
            </div>
          </form>
        </>
        :
        <>
          <div className="header">
            <StyledHeading>{data?.stage_name ? `Edit ${name}` : `Add ${name}`}</StyledHeading>
            {data?.status && <div className={data?.status === "Complete" ? "complete-label" : "pending-label"}>{data?.status}</div>}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <label htmlFor="sub">{name}</label>
              <input
                type="text"
                name="content"
                id="content"
                value={formData.content}
                onChange={handleChange}
                placeholder={`${name} name (250 characters max)`}
                onBlur={handleBlur}
              />
              {touched.content && errors.content && <ErrorInput errorMessage={errors.content} />}
            </div>
            <div className="row">
              <label htmlFor="ai-prompt">AI’s Prompt</label>
              <textarea
                name="prompt"
                id="prompt"
                value={formData.prompt}
                onChange={handleChange}
                placeholder="Prompt here"
                onBlur={handleBlur}
              ></textarea>
              {touched.prompt && errors.prompt && <ErrorInput errorMessage={errors.prompt} />}
            </div>
            <StyledButton style={
              {
                cursor: !isFormValid ? 'not-allowed' : 'pointer',
                color: '#0F172A',
              }
            } type="submit" disabled={!isFormValid}>
              {`Save ${name}`}
            </StyledButton>
            {data?.stage_name &&
              <StyledButton style={!isFormValid ? { cursor: 'not-allowed' } : undefined} type="button" disabled={!isFormValid}
                onClick={onDelete}>
                {`Remove ${name}`}
              </StyledButton>
            }
          </form>
        </>
      }

    </StyledSidebarForm>
  );
};
const StyledErrorInput = styled.p`
  color: red !important;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
`;
const ErrorInput = ({ errorMessage }) => {
  return <StyledErrorInput>{errorMessage}</StyledErrorInput>

}
export default TaskGoalForm;
