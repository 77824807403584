import React, { useState, useEffect } from 'react';
import { getRequest, postRequest, deleteRequest } from '@/utils.js';
import { renderTableRows } from '@/components/admin/Util';
import { AdminTable, Button, TableHeadingDiv, TableHeadingH1 } from '@/views/styles.js';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';

const user_id = window.localStorage.getItem("user_id")

const TasksTable = ({ teamId, setActiveAddOrEditForm, clearModalState, setSidebarModalData }) => {

    const [tasks, setTasks] = useState(null)
    const [task, setTask] = useState(null)

    const [loadingTasks, setLoadingTasks] = useState(true)

    const addTask = (e) => {
        postRequest('/admin/task/add', JSON.stringify({
            stage_name: e.content,
            ais_prompt: e.prompt,
            submitted_by: user_id,
            team_id: parseInt(teamId, 10),
        })).then((response) => {
            if (response?.name?.includes("TypeError")) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                })
            } else {
                loadTasks();
                setActiveAddOrEditForm(false);
                toast.success("Task added successfully!", {
                    theme: "dark",
                    hideProgressBar: true,
                })
                clearModalState()
            }

        });
    };

    const loadTasks = async () => {
        setLoadingTasks(true);
        const tasksData = await getRequest('/admin/tasks?team_id=' + teamId)
        setTasks(tasksData)
        setLoadingTasks(false);
    }

    const onClickRow = (index) => {
        setTask(tasks[index] || null)
        setActiveAddOrEditForm(true)
        setSidebarModalData({
            data: tasks[index],
            onEdit: editTask,
            onAdd: addTask,
            onRemove: removeTask,
            name: 'Task',
        })
    }

    const removeTask = () => {
        deleteRequest('/admin/task/delete', JSON.stringify({
            id: task?.id,
        })).then((response) => {
            if (response?.name?.includes("TypeError")) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
            } else {
                loadTasks();
                setActiveAddOrEditForm(false);
                toast.success("Task removed successfully!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
                clearModalState()
            }
        });
    };

    const editTask = ({ content, prompt }) => {
        postRequest('/admin/task/edit', JSON.stringify({
            id: task?.id,
            stage_name: content,
            ais_prompt: prompt,
            team_id: teamId,
        })).then((response) => {
            if (response?.name?.includes("TypeError")) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
            } else {
                loadTasks();
                setActiveAddOrEditForm(false);
                toast.success("Task updated successfully!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
                clearModalState()
            }
        })
    };

    useEffect(() => {
        loadTasks()
    }, [])

    return (
        <>
            <TableHeadingDiv>
                <TableHeadingH1>Tasks</TableHeadingH1>
                <Button className="admin secondary" onClick={() => {
                    setActiveAddOrEditForm(true)
                    setSidebarModalData({
                        data: undefined,
                        onEdit: undefined,
                        onAdd: addTask,
                        onRemove: undefined,
                        name: 'Task',
                    })
                }
                }> Add Task </Button>
            </TableHeadingDiv>
            <AdminTable
                style={
                    loadingTasks || tasks.length == 0 ? {
                        maxHeight: "90px"
                    } : undefined}
            >
                <div className="head">
                    <p className="team">Stage</p>
                    <p className="date">Submitted By</p>
                    <p className="stat">Status</p>
                    <p className="date">Date Completed</p>
                </div>

                {!loadingTasks && renderTableRows(tasks, 'tasks', onClickRow)}
            </AdminTable>

            <ToastContainer />
        </>
    );
};

export default TasksTable;
