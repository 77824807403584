import React, { useState, useCallback } from 'react';
import {
  useScreenShare,
  useParticipantIds,
  useLocalParticipant,
  useDaily,
  useVideoTrack,
  useParticipantProperty,
  useActiveSpeakerId,
  useDailyEvent,
  DailyVideo
} from '@daily-co/daily-react';
import { Call } from '@/views/video-styles';

function Tile({ id, isScreenShare, isLocal, isHandRaised, isActiveSpeaker, isPinned, size, order }) {
  const videoState = useVideoTrack(id);
  const username = useParticipantProperty(id, 'user_name');
  const audioState = useParticipantProperty(id, 'audio');

  let containerCssClasses = `tile-video ${size}`;

  if (isScreenShare) containerCssClasses += ' tile-screenshare';
  if (isLocal) containerCssClasses += ' self-view';
  if (!videoState.subscribed || videoState.isOff) containerCssClasses += ' no-video';
  if (isPinned) containerCssClasses += ' pinned';

  const style = order !== undefined ? { order } : {};

  return (
    <div className={containerCssClasses} style={style}>
      { !videoState.isOff ? (
        <DailyVideo automirror sessionId={id} type={isScreenShare ? 'screenVideo' : 'video'} />
      ) : (
        <div className="video-placeholder">
          <span>{username}</span>
        <DailyVideo automirror sessionId={id} type={isScreenShare ? 'screenVideo' : 'video'} />
        </div>
      )}
      {isHandRaised && (
        <div className="hand-raised-indicator">
          <span role="img" aria-label="Hand Raised">✋</span>
        </div>
      )}
      <div className="tile-info">
        {username || id} {isLocal && '(you)'}
        <span>{audioState ? '🎤' : '🔇'}</span>
        {isActiveSpeaker && <span>🔊</span>}
        {isPinned && <span>📌</span>}
      </div>
    </div>
  );
}

function SpotlightView({ participant, isScreenShare }) {
  if(participant){
    return (
      <div className="spotlight-view">
        <Tile
          id={participant.session_id}
          isLocal={participant.local}
          isHandRaised={participant.isHandRaised}
          isActiveSpeaker={participant.isActiveSpeaker}
          isPinned={participant.isPinned}
          isScreenShare={isScreenShare}
          size="large"
        />
      </div>
    );
  }
}

function GalleryView({ participants }) {
  return (
    <div className="gallery-view">
      {participants.map((participant, index) => (
        <Tile
          key={participant.session_id}
          id={participant.session_id}
          isLocal={participant.local}
          isHandRaised={participant.isHandRaised}
          isActiveSpeaker={participant.isActiveSpeaker}
          isPinned={participant.isPinned}
          size="small"
          order={index}
        />
      ))}
    </div>
  );
}

function BreakoutView({ participants }) {
  const [currentPage, setCurrentPage] = useState(1);
  const participantsPerPage = 16;
  const totalPages = Math.ceil(participants.length / participantsPerPage);

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const startIndex = (currentPage - 1) * participantsPerPage;
  const visibleParticipants = participants.slice(startIndex, startIndex + participantsPerPage);

  return (
    <div className="breakout-view-container">
      <div className={`breakout-view participants-${visibleParticipants.length}`}>
        {visibleParticipants.map((participant) => (
          <Tile
            key={participant.session_id}
            id={participant.session_id}
            isLocal={participant.local}
            isHandRaised={participant.isHandRaised}
            isActiveSpeaker={participant.isActiveSpeaker}
            isPinned={participant.isPinned}
            size="medium"
          />
        ))}
      </div>
      {totalPages > 1 && (
        <div className="pagination-controls">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
        </div>
      )}
    </div>
  );
}

function CallFrame({ user, call, pinnedParticipant, breakout = false, raisedHands }) {
  const { screens } = useScreenShare();
  const participants = useParticipantIds();
  const localParticipant = useLocalParticipant();
  const daily = useDaily();
  const isOwner = user?.name === call?.owner?.name;
  const isScreenSharing = screens.length > 0;
  const activeSpeakerId = useActiveSpeakerId();

  const allParticipants = participants
    .map(id => {
      const participant = daily.participants()[id];
      return participant ? {
        ...participant,
        isHandRaised: raisedHands.has(id),
        isActiveSpeaker: id === activeSpeakerId,
        isPinned: id === pinnedParticipant
      } : null;
    })
    .filter(Boolean)
    .sort((a, b) => {
      if (a.isPinned && !b.isPinned) return -1;
      if (!a.isPinned && b.isPinned) return 1;
      if (a.isActiveSpeaker && !b.isActiveSpeaker) return -1;
      if (!a.isActiveSpeaker && b.isActiveSpeaker) return 1;
      if (a.isHandRaised && !b.isHandRaised) return -1;
      if (!a.isHandRaised && b.isHandRaised) return 1;
      if (a.local) return -1;
      if (b.local) return 1;
      return 0;
    });

  // Ensure local participant is included
  if (localParticipant && !allParticipants.find(p => p.local)) {
    allParticipants.unshift({
      ...localParticipant,
      isHandRaised: raisedHands.has(localParticipant.session_id),
      isActiveSpeaker: localParticipant.session_id === activeSpeakerId,
      isPinned: localParticipant.session_id === pinnedParticipant
    });
  }

  if (breakout) {
    return (
      <Call className="call-frame breakout-room">
        <BreakoutView participants={allParticipants} />
      </Call>
    );
  }

  let spotlightParticipant = null;
  if (isScreenSharing) {
    spotlightParticipant = screens[0];
  } else if (pinnedParticipant && daily.participants()[pinnedParticipant]) {
    spotlightParticipant = daily.participants()[pinnedParticipant];
  } else if (isOwner && localParticipant) {
    spotlightParticipant = localParticipant;
  } else {
    const ownerParticipant = Object.values(daily.participants()).find(p => p.user_name === call?.owner?.name);
    spotlightParticipant = ownerParticipant || localParticipant;
  }

  if (!spotlightParticipant) {
    console.error('No spotlight participant found');
    return <div>Loading...</div>;
  }

  const galleryParticipants = allParticipants.filter(p =>
    isScreenSharing || p.session_id !== spotlightParticipant.session_id
  );

  if ((isScreenSharing || !isOwner) && localParticipant && !galleryParticipants.find(p => p.local)) {
    galleryParticipants.push({
      ...localParticipant,
      isHandRaised: raisedHands.has(localParticipant.session_id),
      isActiveSpeaker: localParticipant.session_id === activeSpeakerId,
      isPinned: localParticipant.session_id === pinnedParticipant
    });
  }

  const limitedGalleryParticipants = galleryParticipants.slice(0, 5);

  return (
    <Call className="call-frame">
      <SpotlightView
        participant={spotlightParticipant}
        isScreenShare={isScreenSharing}
      />
      {limitedGalleryParticipants.length > 0 &&
        <GalleryView
          participants={limitedGalleryParticipants}
        />
      }
    </Call>
  );
}

export default CallFrame;
