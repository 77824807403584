import React, { useState, useEffect } from 'react';
import { getRequest, postRequest, deleteRequest } from '@/utils.js';
import { renderTableRows } from '@/components/admin/Util';
import { ToastContainer, toast } from 'react-toastify';
import { AdminTable, Button, TableHeadingDiv, TableHeadingH1 } from '@/views/styles.js';

const user_id = window.localStorage.getItem("user_id")

const GoalsTable = ({ teamId, setActiveAddOrEditForm, clearModalState, setSidebarModalData }) => {
    const [goals, setGoals] = useState(null)
    const [goal, setGoal] = useState(null)
    const [loadingGoals, setLoadingGoals] = useState(true)

    const addGoal = async (e) => {
        postRequest('/admin/goal/add', JSON.stringify({
            stage_name: e.content,
            ais_prompt: e.prompt,
            team_id: parseInt(teamId, 10),
            submitted_by: user_id,
        })).then((response) => {
            if (response?.name?.includes("TypeError")) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                })
            } else {
                loadGoals();
                setActiveAddOrEditForm(false);
                toast.success("Goal added successfully!", {
                    theme: "dark",
                    hideProgressBar: true,
                })
                clearModalState()
            }

        });
    };
    const loadGoals = async () => {
        setLoadingGoals(true);
        const goalsData = await getRequest('/admin/goals?team_id=' + teamId);
        setGoals(goalsData)
        setLoadingGoals(false);
    }

    const onClickRow = (index) => {
        setGoal(goals[index] || null)
        setActiveAddOrEditForm(true)
        setSidebarModalData({
            data: goals[index],
            onEdit: editGoal,
            onAdd: addGoal,
            onRemove: removeGoal,
            name: 'Goal',
        })
    }

    const removeGoal = () => {
        deleteRequest('/admin/goal/delete', JSON.stringify({
            id: goal?.id,
        })).then((response) => {
            if (response?.name?.includes("TypeError")) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
            } else {
                loadGoals();
                setActiveAddOrEditForm(false);
                toast.success("Goal removed successfully!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
                clearModalState()
            }

        })
    };

    const editGoal = ({ content, prompt }) => {
        postRequest('/admin/goal/edit', JSON.stringify({
            id: goal?.id,
            stage_name: content,
            ais_prompt: prompt,
            team_id: teamId,
        })).then((response) => {
            if (response?.name?.includes("TypeError")) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
            } else {
                loadGoals();
                setActiveAddOrEditForm(false);
                toast.success("Goal updated successfully!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
                clearModalState()
            }

        })
    };

    useEffect(() => {
        loadGoals()
    }, [])

    return (
        <>
            <TableHeadingDiv>
                <TableHeadingH1>Goals</TableHeadingH1>
                <Button className="admin secondary" onClick={() => {
                    setActiveAddOrEditForm(true)
                    setSidebarModalData({
                        data: undefined,
                        onEdit: undefined,
                        onAdd: addGoal,
                        onRemove: undefined,
                        name: 'Goal',
                    })
                }
                }> Add Goal </Button>

            </TableHeadingDiv>
            <AdminTable
                style={
                    loadingGoals || goals.length == 0 ? {
                        maxHeight: "90px"
                    } : undefined}
            >
                <div className="head">
                    <p className="team">Stage</p>
                    <p className="date">Submitted By</p>
                    <p className="stat">Status</p>
                    <p className="date">Date Completed</p>
                </div>

                {!loadingGoals && renderTableRows(goals, 'goals', onClickRow)}
            </AdminTable>
            <ToastContainer />

        </>
    );
};

export default GoalsTable;
