import styled from 'styled-components';
import SpotLight from '@/public/spotlight.png';

const Onboarding = styled.div`
display: flex;
flex-direction: column;
margin: 0 auto;
align-items: center;
justify-content: center;

img{
  width: 86px;
  height: 86px;
  &.big{
    width: 186px;
    height: 186px;
  }
}

span{
  padding: 36px 0;
  max-width: 90%;
  text-align: center;

}

h3{
  font-size: 18px;
  font-weight: 800;
  padding-bottom: 24px;
  max-width: 500px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h4{
  font-size: 18px;
  max-width: 500px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  padding-bottom: 24px;
}



button{
  background-color: #6A53F5;
  color: white;
  border-radius: 16px;
  padding: 16px;
  min-width: 100px;
  margin-top: 36px;
  &.secondary{
    background-color: transparent;
  }
}

textarea{
  width: 200%;
  height: 200px;
  border-radius: 16px;
  background-color: rgba(49, 49, 49, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 16px;
}

input{
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(49, 49, 49, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  width: 100%;
}
`;

const Container = styled.div`
  min-width: calc(100vw - 100px);
  background-image: url(${SpotLight});
  background-size: cover;
  background-color: var(--bg);
`

const Header = styled.div`
padding: 33px 24px;
font-size: 20px;
border-bottom: 1px solid rgba(255, 255, 255, 0.1);
max-height: 94px;
min-width: 100%;
display: flex;
background-color: var(--bg);
align-items: center;

img{
  width: 24px;
  height: 24px;
  margin: 0 24px;
  cursor: pointer;
}

`;

const Todos = styled.div`
display: flex;
flex-direction: row;
.header{
  position: fixed;
  top: 94px;
  right: 0px;
  display: flex;
  flex-direction: row;
  height: 65px;
  width: calc(100vw - 140px);
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  background-color: var(--bg);
  border-bottom: 1px solid #282625;
  button{
    background-color: rgba(193,245,83,0.12);
    color: white;
    border-radius: 8px;
    padding: 18px 12px;
      max-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
      width: 24px;
      height: 24px;
    }
  }

}
`;

const Tasks = styled.div`
max-width: 476px;
min-width: 476px;
width: 476px;
border-right: 1px solid #282625;
max-height: calc(100vh - 88px);
min-height: calc(100vh - 88px);
overflow-y: scroll;

h3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 20px;
  background-color: var(--bg);
  border-bottom: 1px solid #282625;
  .actions{
    display: flex;
    felx-direction: row;
    > div {
      padding: 4px 6px;
      transition-duration: 0.3s;
      border-radius: 16px;
      cursor: pointer;

      &:hover{
        background-color: #353333;
      }
    }

    img{
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

  }
}
.empty{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 215px;
  align-items: center;
  padding: 24px;
  color: white;
  background-color: rgba(171, 171,171,0.05);
  margin-bottom: -64px;
  span{
    font-size: 16px;
    padding: 12px;
  }
  h1{
    font-size: 64px;
    padding:24px;
  }
`;

const Task = styled.div`
display: flex;
flex-direction: row;
padding: 24px;
border-bottom: 1px solid #282625;
background-color: rgba(67, 67, 67, 0.06);
backdrop-filter: blur(14.5px);
align-items: center;
transition-duration: 0.3s;
cursor: pointer;

&.active{
  background-color: rgba(67, 67, 67, 0.52);
}

&:hover{
  transition-duration: 0.3s;
  background-color: rgba(67, 67, 67, 0.22);
}

span{
  display: block;
  width: 90%;
  padding: 12px;
  margin-right: 10%;
}
img{
  width: 24px;
  height: auto;
}
`;

const Goal = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin: 24px;
padding: 12px;
border: 1px solid #282625;
border-radius: 16px;

progress{
  color: var(--green);
  &::-webkit-progress-value, &::-moz-progress-value{
    background-color: var(--green);
  }
}

img{
  width: 66px;
  height: 66px;
  background-color: rgba(67, 67, 67, 0.06);
  border-radius: 16px;
  margin-right: 12px;
  padding: 20px;
}
> div {
  max-width: 50%;
  margin-right: 32px;
  p{
    font-size: 11px;
    font-wight: 200;
    padding: 10px 0;
    display: block;
  }
  span{
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;

  }
}
button{
  background-color: #6A53F5;
  color: white;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 8px;
}

`
const TaskGoalForm = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
background-color: var(--bg);
position: fixed;
top: 50px;
padding: 8px 24px;
left: 300px;
z-index: 99999;
width: 600px;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
border-radius: 16px;
border: 1px solid #282625;

input{
  border: 0px solid #282625;
  background-color: transparent;
  color: white;
  padding: 12px;
  font-size: 16px;
  border-radius: 16px;
  margin-bottom: 12px;
  dispaly: block;
  flex-grow: 1;
}
span {
  padding: 0 12px;
}

button{
  background-color: #6A53F5;
  color: white;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 8px;
}
`
export{
  Onboarding,
  Container,
  Header,
  Todos,
  Tasks,
  Task,
  Goal,
  TaskGoalForm
}

