import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import {
  VeltProvider, VeltComments,
  VeltCommentTool
} from '@veltdev/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);


// import reportWebVitals from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
