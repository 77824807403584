import React, { useCallback, forwardRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button } from './../views/styles';
import Icon from './../components/Icon';

const Container = styled.div`
cursor: pointer;
max-width: 280px;
min-width: 280px;
height: 125px;
box-sizing: content-box;
display: flex;
align-items: center;
padding: 0 32px;
border-radius: 32px;
margin: 16px 0;
transition-duration: 0.3s;
background-color: #283130;
position: relative;
overflow: hidden;


&:hover{
  transform: scale(1.25);
  transition-duration: 0.3s;
  justify-content: space-between;
  ::before{
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5));
    transform: translateX(-100%) rotate(45deg);
    opacity: 0.5;
    pointer-events: none;
    animation: shine 0.75s ease-out forwards;
  }
  &.active{
    transform: scale(1.33);
  }
}

&.active{
  transform: scale(1.25);
  background: #3E4746;
  margin: 32px 0;
  max-width: 350px;
  min-width: 350px;
}

button{
  background-color: var(--green);
  color: var(--black);
  padding: 12px 16px;
  border-radius: 12px;
  margin-left: 16px;
}

&.completed{
  background-color: #1D2625;
  opacity: 0.9;
}

span{
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--white);
  em{
    text-transform: uppercase;
    opacity: 0.5;
    padding-right: 8px;
  }
}
p{
  margin: 0;
  color: var(--white);
  padding-bottom: 8px;
}
.graphic{
  width: 60px;
  height: 60px;
  border-radius: 16px;
  margin-right: 32px;
  img{
    padding: 16px;
    background: var(--dark);
    width: 58px;
    height: 60px;
    padding: 16px;
    background: var(--dark);
    padding-left: 15px;
    border-radius: 16px;
    &.completed{
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
}

`;

const Lesson = forwardRef(({ user, lesson, completed, active }, ref) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = React.useState(active);
  const goto = () => {
    navigate(`/${lesson.type.includes('pre') ? 'prework' : 'lesson'}/${lesson.id}`)
  };

  function getRelativeTime(date) {
    const now = new Date();
    const targetDate = date;
    const diffInSeconds = Math.floor((targetDate - now) / 1000);

    const units = [
        { max: 60, value: 1, name: 'second', plural: 'seconds' },
        { max: 3600, value: 60, name: 'minute', plural: 'minutes' },
        { max: 86400, value: 3600, name: 'hour', plural: 'hours' },
        { max: 604800, value: 86400, name: 'day', plural: 'days' },
        { max: 2592000, value: 604800, name: 'week', plural: 'weeks' },
        { max: 31536000, value: 2592000, name: 'month', plural: 'months' },
        { max: Infinity, value: 31536000, name: 'year', plural: 'years' }
    ];

    let relativeTime = '';
    let isFuture = diffInSeconds > 0;
    let absDiffInSeconds = Math.abs(diffInSeconds);

    for (let unit of units) {
        if (absDiffInSeconds < unit.max) {
            let value = Math.floor(absDiffInSeconds / unit.value);
            let unitName = value === 1 ? unit.name : unit.plural;
            if (value === 0 && unit.name === 'day') {
                relativeTime = 'today';
            } else if (value === 1 && unit.name === 'day') {
                relativeTime = isFuture ? 'tomorrow' : 'yesterday';
            } else {
                relativeTime = `${value} ${unitName} ${isFuture ? 'from now' : 'ago'}`;
            }
            break;
        }
    }

    return relativeTime;
}

  const deadline = useCallback(() => {
    if(lesson.due){
      if(lesson.due[user.cohort]){
        const d = new Date(lesson.due[user.cohort])
        return d.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
      }
    }
    return ''
  }, [lesson.due, user.cohort])

  return (
    <Container
      onMouseEnter={() => setIsActive(true) }
      onMouseLeave={( () => { if(!active) {setIsActive(false)} }  )}
      ref={ref} className={`${completed ? 'completed' : ''} ${isActive ? 'active' : ''}`} onClick={goto}>
      <div className="graphic">
        {
          completed &&
          <Icon className="completed" name="complete" size={24} color="var(--white)" />
        }
        {
          !completed &&
           <img src={lesson.img} alt="thumbnail" />
        }
      </div>
      <div className="title">
        <p> {lesson.name} </p>
        {
          deadline() &&
          <span> <em>DEADLINE</em> {deadline()} </span>
        }
      </div>

      { isActive &&
        <Button className="primary">{completed ? 'Edit' : 'Start'}</Button>
      }
     </Container>
  );
});

export default Lesson;
