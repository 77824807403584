
// 1) Create a component that will represent your document 

//Warning: Make sure this is a child component to VeltProvider 
//and not within the same file where VeltProvider is placed.

// 2) Get the Velt client
import { useVeltClient } from '@veltdev/react';
import { useEffect, useState } from 'react';
import ikigai from '@/public/ikigai.webp';
import {
    VeltProvider,
    VeltComments,
    VeltCommentTool
} from '@veltdev/react';

export default function MyDocument({ children }) {

    const { client } = useVeltClient();

    // 3) Create a useEffect hook

    useEffect(() => {
        if (client) {
            // 4) Set a document ID
            client.setDocument('unique-document-id');
        }
    }, [client]);

    return (
        <div>
            <VeltCommentTool/>
            <img src={ikigai} />
        </div>

    );
}