import React  from 'react';

import styled  from 'styled-components'

const CoverLoader = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,0.7);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loader = () => {
  return (
    <CoverLoader>
      <div className="loader"></div>
   </CoverLoader>
  );
};

export default Loader;
