
import { Sub, NullStateTable } from '@/views/styles.js';
import styled  from 'styled-components'

const StyledStageName = styled.div`
    display: grid;
    grid-template-columns: 34px 1fr;
    column-gap: 5px;
    width: 250px;
    align-items: center;
`;
const StyledSpan = styled.span`
    text-align: center;
    max-width: 34px;
    height: 27px;
    align-items: center;
`;
export const renderTableRows = (items, name, onClickRow) => {
    return items && items.length > 0 ? items.map((item, i) => (
        <Sub key={`${name}-${item.stage_name}-${item.id}`} onClick={() => onClickRow(i)} style={onClickRow ? {
            cursor: 'pointer'
        } : undefined}>
            <StyledStageName>
                <StyledSpan>{item.id}</StyledSpan> {item.stage_name}
            </StyledStageName>
            <div className="date">{item.submitted_by}</div>
            <div className="stat">
                <div className={`status ${item.status === 'Complete' ? 'good' : 'not'}`}>{item.status || "Pending"}</div>
            </div>
            <div className="date">{item.date_completed ? new Date(item.date_completed).toDateString() : 'N/A'}</div>
        </Sub>
    )) : <NullStateTable>No {name} added yet.</NullStateTable>;
};
