import React, { useState, useEffect, useCallback, useRef } from 'react';
import  { postRequest, getRequest, PROGRAMS } from '@/utils.js';
import  { AdminView, AdminHeader, AdminSidebarForm, Button } from '@/views/styles.js';
import UserSelect from '@/components/admin/UserSelect';
import Nav from '@/components/admin/Nav.js';
import Icon from '@/components/Icon.js';
import styled from 'styled-components';

const Content = styled.div`
  height: 100vh;
  flex-grow: 1;
  overflow-y: scroll;
`

const Page = styled.div`
  display: flex;
  flex-direction: row;
`

const CallBlock = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid var(--admin);
  border-radius: 8px;
  margin: 32px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  h2{
    padding: 12px;
    font-weight: 600;
    border-bottom: 1px solid var(--admin);
  }
`

const ResourceGroup = styled.div`
  display: flex;
  flex-direction: column;
  h2{
    padding: 16px;
    font-weight: 600;
    border-bottom: 1px solid var(--admin);
    border-top: 1px solid var(--admin);
  }

  &:first-child{
    h2{
      border-top: none;
    }
  }
  &:last-child{ border-bottom: none }
`

const Resource = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--admin);
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover{
    background-color: #F1F5F9;
  }

  h3{
    font-weight: 500;
    margin-right: 16px;
    min-width: 350px;
    max-width: 350px;
  }
  p{
    color: black;
    width: 125px;
  }
  >div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  img{
    border-radius: 4px;
    width: 75px;
    height: 50px;
    object-fit: cover;
    margin-right: 16px;
  }
  &:last-child{ border-bottom: none }
`

const Calls = () => {
  const [calls, setCalls] = useState([])
  const [editing, setEditing] = useState(null)
  const [user, setUser] = useState({permissions: []})
  const [users, setUsers] = useState({ advisor: [], user: [] })

  const loadCalls = async () => {
    const u = await getRequest('/user')
    const us = await getRequest('/admin/users')
    setUser(u)
    setUsers(us)

    const data = await getRequest('/admin/calls')
    setCalls(data);
  }

  useEffect(() => {
    loadCalls()
  }, [])

  const deleteCall = async (id) => {
    if (window.confirm("Are you sure you want to delete this call?")) {
      await getRequest('/admin/call/delete?id=' + id);
      setEditing(null);
      loadCalls();
    }
  };

  const togglePublish = async (id) => {
    if (window.confirm("Are you sure you want to start this call?")) {
      await getRequest('/admin/call/publish?id=' + id);
      loadCalls();
      setEditing({...editing, published: !editing.published});
    }
  };

  const save = async (e) => {
    if (window.confirm("Are you sure you want to save these changes?")) {
      await postRequest('/admin/calls', JSON.stringify(e));
      loadCalls();
    }
  };

  const createCall = async (e) => {
    console.log(e)
    await postRequest('/admin/call/add', JSON.stringify(e));
    loadCalls();
    setEditing(null);
  };

  const filteredUsers = (type) => {
    const added = editing.people;
    return users[type].filter(
      (user) => !added.find((addedUser) => addedUser.id === user.id)
    );
  };

  const removeTeamMember = (index) => {
    setEditing({
      ...editing,
      people: editing.people.filter((_, i) => i !== index),
    });
  };


  const addMode = () => {
    setEditing(null);
    setTimeout(() => { setEditing({people: []}) }, 300)
  }

  return (
    <AdminView>
      <Nav />

      { (!user || !user.permissions.includes('pd')) && <div class="max-center"> 
        <Icon name="close" size={50}></Icon>
        <p> You do not have permission to view this page. </p>
      </div>}

      { user && user.permissions.includes('pd') &&
        <Content>
          <AdminHeader>
            <h1>Calls</h1>
            <Button className="admin primary" onClick={() => { addMode() }}> <Icon name="add" size={10}></Icon> Add Call</Button>
          </AdminHeader>

          <Page>
            <CallBlock>
              <ResourceGroup >
                { calls.map((call) => {
                  return (
                    <Resource onClick={() => {setEditing(call)}}>
                      <div>
                        <h3>{call.title}</h3>
                        <p>{call.name}</p>
                      </div>

                    </Resource>
                  )
                })}
              </ResourceGroup>
            </CallBlock>
          </Page>
        </Content>
      }

      {editing !== null &&
        <AdminSidebarForm>
          <AdminHeader className="sec-header">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div onClick={() => { setEditing(null)} }>
                <Icon name="close" size={8} ></Icon>
              </div>
              <h2>Edit call</h2>
            </div>
            <div>
              {!editing._id &&
                <Button className="admin primary" onClick={() => { createCall(editing) }}> Create Call </Button>
              }
              {editing._id &&
                <>
                  <Button className="admin danger" onClick={() => { deleteCall(editing.id) }}> Delete </Button>
                  <Button className="admin secondary" onClick={() => { save(editing) } }> Save </Button>
                  <Button className="admin primary" onClick={() => { togglePublish(editing.id) } }> {editing.published ? 'end call' : 'start call' } </Button>
                </>
              }
            </div>
          </AdminHeader>

          <form>
            <div className="row">
              <div className="group">
                <label htmlFor="name">Title</label>
                <input
                  type="text" name="name" id="name" placeholder="Call Name"
                  value={editing.title}
                  onChange={(e) => {setEditing({...editing,title: e.target.value}) }}/>
              </div>

              <div className="group">
                <label htmlFor="slug">Number of Breakouts</label>
                <input type="number" name="bos" id="bos" placeholder="1"
                  value={editing.breakouts}
                  onChange={(e) => {setEditing({...editing, breakouts: parseInt(e.target.value)}) }}
                />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label>Team</label>

                {editing.people.map((member, index) => (
                  <div key={index} className="user">
                    <img src={member.picture}/>
                    <p>{member.name}</p>
                    <button type="button" onClick={() => removeTeamMember(index)}>x</button>
                  </div>
                ))}

                <UserSelect
                  options={filteredUsers('user')}
                  onSelect={(member) => { setEditing({ ...editing, people: [...editing.people, member], }) }}
                  placeholder="Add student"
                />
              </div>
            </div>



          </form>
        </AdminSidebarForm>
      }
    </AdminView>
  );
};

export default Calls;
