import React, { useEffect } from 'react';
import { useAudioRecorder } from "react-audio-voice-recorder";
import Icon from "@/components/Icon";
import { Button } from "@/views/styles";
import { ToastContainer } from 'react-toastify';
import { transcribeAudio } from '@/utils.js';

const AudioRecorder = ({ recordingState, setRecordingState, askModel, setMessageLoading }) => {
    const {
        startRecording,
        stopRecording,
        recordingBlob,
    } = useAudioRecorder();


    const handleStartRecording = () => {
        setRecordingState('recording');
        startRecording();
    };

    useEffect(() => {
        const processRecording = async () => {
            if (recordingBlob && recordingState === 'generating') {
                await sendAudio();
                setRecordingState('start');
            }
        };
        processRecording();
    }, [recordingBlob, recordingState]);

    const handleStopRecording = () => {
        stopRecording();
        setRecordingState('generating');
    };

    // transcribe audio and send to openai chat
    const sendAudio = async () => {
        if (recordingState !== 'generating') return;
        const audioTranscript = await transcribeAudio(recordingBlob)
        if (audioTranscript) {
            // update with the audio content
            askModel(audioTranscript, true);
            setMessageLoading(false)
            setRecordingState('start');

        }
    }

    return (
        <>
            <ToastContainer />
            <Button
                onClick={recordingState === "recording" ? handleStopRecording : handleStartRecording}
            >
                <Icon name={recordingState === "recording" ? "mic-on" : "microphone"}></Icon>
            </Button>
        </>
    );
};

export default AudioRecorder;