import React, { useState, useEffect } from 'react';
import { getRequest, postRequest } from '@/utils.js';
import { AdminTable, TableHeadingDiv, TableHeadingH1, Sub, NullStateTable } from '@/views/styles.js';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledButton = styled.button`
    border-radius: 6px;
    border: 1px solid var(--slate-200, #E2E8F0);
    background: #FFF;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
`;

const TranscriptsTable = ({ teamId, setActiveAddOrEditForm, clearModalState, setSidebarModalData }) => {

    const [transcripts, setTranscripts] = useState([])
    const [transcript, setTranscript] = useState(null)
    const [loadingTranscripts, setLoadingTranscripts] = useState(true)

    const loadTranscripts = async () => {
        // TODO call transcripts endpoint when we decide where to save chat story
        // setLoadingTranscripts(true);
        // const transcriptsData = await getRequest('/admin/transcripts?team_id=' + teamId)
        // setTranscripts(transcriptsData)
        // setLoadingTranscripts(false);
    }

    const onClickRow = (index) => {
        setTranscript(transcripts[index] || null)
        setActiveAddOrEditForm(true)
        // call openai api to summarize chat story
        // TODO implement when we decide where to save chat story data
        setSidebarModalData({
            data: transcripts[index],
            onEdit: undefined,
            onAdd: undefined,
            onRemove: undefined,
            name: 'Transcript',
            customContent: <>
                <div className="row">
                    <label htmlFor="overview">Overview</label>
                    <textarea
                        name="overview"
                        id="overview"
                        value=""
                        readOnly
                        placeholder=""
                    ></textarea>
                </div>
            </>
        })
    }

    useEffect(() => {
        loadTranscripts()
    }, [])

    return (
        <>
            <TableHeadingDiv>
                <TableHeadingH1>Poppy Transcripts</TableHeadingH1>
            </TableHeadingDiv>
            <AdminTable
                style={loadingTranscripts || transcripts.length == 0 ? {
                    maxHeight: "90px"
                } : undefined}
            >
                <div className="head">
                    <p className="date">Date</p>
                    <p></p>
                </div>

                {!loadingTranscripts &&
                    transcripts && transcripts.length > 0 ? transcripts.map((transcript, i) => (
                        <Sub key={`transcript-table-row-${transcript.id}`}>
                            <div className="date">{transcript.date ? new Date(transcript.date).toDateString() : 'N/A'}</div>
                            <div className="cta">
                                <StyledButton type="button"
                                    onClick={onClickRow}
                                >
                                    View transcript
                                </StyledButton>
                            </div>
                        </Sub>
                    )) : <NullStateTable>No transcripts added yet.</NullStateTable>
                }
            </AdminTable>

            <ToastContainer />
        </>
    );
};

export default TranscriptsTable;
