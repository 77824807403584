import React from 'react';
import YouIcon from './../public/icons/you.svg';
import LearnIcon from './../public/icons/learn.svg';
import DiscoverIcon from './../public/icons/discover.svg';
import BuildIcon from './../public/icons/build.svg';
import SearchIcon from './../public/icons/search.svg';
import BackIcon from './../public/icons/back.svg';
import PaperClipIcon from './../public/icons/paper-clip.svg';
import HomeIcon from './../public/icons/home.svg';
import VideoIcon from './../public/icons/video.svg';
import CloseIcon from './../public/icons/close.svg';
import BlackCloseIcon from './../public/icons/black-close.svg';
import AttachIcon from './../public/icons/attach.svg';
import UploadIcon from './../public/icons/upload.svg';
import EditIcon from './../public/icons/edit.svg';
import PencilInFrameEditIcon from './../public/icons/edit2.svg';
import CompleteIcon from './../public/icons/complete.svg';
import PlayIcon from './../public/icons/play.svg';
import AddIcon from './../public/icons/add.svg';
import LessonsIcon from './../public/icons/lessons.svg';
import ResourcesIcon from './../public/icons/resources.svg';
import TeamsIcon from './../public/icons/teams.svg';
import MicIcon from './../public/icons/mic.svg';
import FileIcon from './../public/icons/file.svg';
import UploadGreenIcon from './../public/icons/upload-green.svg';
import PlayBig from './../public/icons/play-big.svg';
import RecordingIcon from './../public/icons/recording.svg';
import HandIcon from './../public/icons/hand.svg';
import EmojiIcon from './../public/icons/emoji.svg';
import DotDotDotIcon from './../public/icons/dotdotdot.svg';
import ScreenShareIcon from './../public/icons/screenshare.svg';
import HangupIcon from './../public/icons/hangup.svg';
import AnswerIcon from './../public/icons/answer-phone.svg';
import CameraOnIcon from './../public/icons/camera-on.svg';
import CameraOffIcon from './../public/icons/camera-off.svg';
import MicOnIcon from './../public/icons/mic-on.svg';
import MicOffIcon from './../public/icons/mic-off.svg';
import SendArrow from './../public/icons/send-arrow.svg';
import ReturnIcon from './../public/icons/return.svg';
import CarretDownGreen from './../public/icons/carret-down-green.svg';
import CarretDownRed from './../public/icons/carret-down-red.svg';
import SelectedIcon from './../public/icons/selected.svg';
import AdminVideoIcon from './../public/icons/admin_video.svg';
import CompleteGoal from './../public/icons/complete-goal.svg';
import CompletedGoal from './../public/icons/completed-goal.svg';
import RemoveGoal from './../public/icons/remove-goal.svg';
import AddTaskIcon from './../public/icons/add-task.svg';
import OverviewIcon from './../public/icons/overview.svg';
import CartIcon from './../public/icons/cart.svg';
import TasksIcon from './../public/icons/tasks.svg';
import BackNavIcon from './../public/icons/back-nav.svg';
import MicrophoneIcon from './../public/icons/microphone.svg';

const Icon = ({name, size='18'}) => {
  if(name === 'you'){ return ( <img className="icon" width={size} height={size} src={YouIcon} alt="You Icon"/>); }
  if(name === 'learn'){ return ( <img className="icon" width={size} height={size} src={LearnIcon} alt="Learn Icon"/>); }
  if(name === 'discover'){ return ( <img className="icon" width={size} height={size} src={DiscoverIcon} alt="Discover Icon"/>); }
  if(name === 'home'){ return ( <img className="icon" width={size} height={size} src={HomeIcon} alt="Home Icon Icon"/>); }
  if(name === 'video'){ return ( <img className="icon" width={size} height={size} src={VideoIcon} alt="Video Icon Icon"/>); }
  if(name === 'build'){ return ( <img className="icon" width={size} height={size} src={BuildIcon} alt="Build Icon"/>); }
  if(name === 'close'){ return ( <img className="icon" width={size} height={size} src={CloseIcon} alt="Close Icon"/>); }
  if(name === 'close-black'){ return ( <img className="icon" width={size} height={size} src={BlackCloseIcon} alt="Close Icon"/>); }
  if(name === 'attach'){ return ( <img className="icon" width={size} height={size} src={AttachIcon} alt="Attach Icon"/>); }
  if(name === 'upload'){ return ( <img className="icon" width={size} height={size} src={UploadIcon} alt="Upload Icon"/>); }
  if(name === 'search'){ return ( <img className="icon" width={size} height={size} src={SearchIcon} alt="Search Icon"/>); }
  if(name === 'back'){ return ( <img className="icon" width={size} height={size} src={BackNavIcon} alt="Back Icon"/>); }
  if(name === 'next'){ return ( <img className="icon" width={size} height={size} src={BackIcon} style={{transform: 'rotate(180deg)'}} alt="Next Icon"/>); }
  if(name === 'paper-clip'){ return ( <img className="icon" width={size} height={size} src={PaperClipIcon} alt="Paper Clip Icon"/>); }
  if(name === 'edit'){ return ( <img className="icon" width={size} height={size} src={EditIcon} alt="Edit Icon"/>); }
  if(name === 'pencil-in-frame'){ return ( <img className="icon" width={size} height={size} src={PencilInFrameEditIcon} alt="Edit Icon"/>); }
  if(name === 'complete'){ return ( <img className="icon" width={size} height={size} src={CompleteIcon} alt="Complete Icon"/>); }
  if(name === 'play'){ return ( <img className="icon" width={size} height={size} src={PlayIcon} alt="Play Icon"/>); }
  if(name === 'play-big'){ return ( <img className="icon" width={size} height={size} src={PlayBig} alt="Play Icon"/>); }
  if(name === 'add'){ return ( <img className="icon" width={size} height={size} src={AddIcon} alt="Add Icon"/>); }
  if(name === 'teams'){ return ( <img className="icon" width={size} height={size} src={TeamsIcon} alt="Teams Icon"/>); }
  if(name === 'lessons'){ return ( <img className="icon" width={size} height={size} src={LessonsIcon} alt="Lesson Icon"/>); }
  if(name === 'resources'){ return ( <img className="icon" width={size} height={size} src={ResourcesIcon} alt="Resrouces Icon"/>); }
  if(name === 'mic'){ return ( <img className="icon" width={size} height={size} src={MicIcon} alt="Mic Icon"/>); }
  if(name === 'file'){ return ( <img className="icon" width={size} height={size} src={FileIcon} alt="File Icon"/>); }
  if(name === 'upload-green'){ return ( <img className="icon" width={size} height={size} src={UploadGreenIcon} alt="Upload Icon"/>); }
  if(name === 'play-big'){ return ( <img className="icon" width={size} height={size} src={PlayBig} alt="Play Icon"/>); }
  if(name === 'recording'){ return ( <img className="icon" width={size} height={size} src={RecordingIcon} alt="Recording Icon"/>); }
  if(name === 'screenshare'){ return ( <img className="icon" width={size} height={size} src={ScreenShareIcon} alt="Screenshare Icon"/>); }
  if(name === 'hand'){ return ( <img className="icon" width={size} height={size} src={HandIcon} alt="Hand Icon"/>); }
  if(name === 'emoji'){ return ( <img className="icon" width={size} height={size} src={EmojiIcon} alt="Emoji Icon"/>); }
  if(name === 'dotdotdot'){ return ( <img className="icon" width={size} height={size} src={DotDotDotIcon} alt="Dot dot dot Icon"/>); }
  if(name === 'hangup'){ return ( <img className="icon" width={size} height={size} src={HangupIcon} alt="Hangup Icon"/>); }
  if(name === 'answer'){ return ( <img className="icon" width={size} height={size} src={AnswerIcon} alt="AnswerIcon"/>); }
  if(name === 'mic-on'){ return ( <img className="icon" width={size} height={size} src={MicOnIcon} alt="Mic On Icon"/>); }
  if(name === 'mic-off'){ return ( <img className="icon" width={size} height={size} src={MicOffIcon} alt="Mic Off Icon"/>); }
  if(name === 'camera-on'){ return ( <img className="icon" width={size} height={size} src={CameraOnIcon} alt="Camera On Icon"/>); }
  if(name === 'camera-off'){ return ( <img className="icon" width={size} height={size} src={CameraOffIcon} alt="Camera Off Icon"/>); }
  if(name === 'send-arrow'){ return ( <img className="icon" width={size} height={size} src={SendArrow} alt="Send Icon"/>); }
  if(name === 'return'){ return ( <img className="icon" width={size} height={size} src={ReturnIcon} alt="Return Icon"/>); }
  if(name === 'carret-down-red'){ return ( <img className="icon" width={size} height={size} src={CarretDownRed} alt="Carret Down Icon"/>); }
  if(name === 'carret-down-green'){ return ( <img className="icon" width={size} height={size} src={CarretDownGreen} alt="Carret Down Icon"/>); }
  if(name === 'selected'){ return ( <img className="icon" width={size} height={size} src={SelectedIcon} alt="Selected Icon"/>); }
  if(name === 'admin-video'){ return ( <img className="icon" width={size} height={size} src={AdminVideoIcon} alt="Admin video Icon"/>); }
  if(name === 'remove-goal'){ return ( <img className="icon" width={size} height={size} src={RemoveGoal} alt="Remove Goal Icon"/>); }
  if(name === 'complete-goal'){ return ( <img className="icon" width={size} height={size} src={CompleteGoal} alt="Complte Goal Icon"/>); }
  if(name === 'completed-goal'){ return ( <img className="icon" width={size} height={size} src={CompletedGoal} alt="Completed Goal Icon"/>); }
  if(name === 'cart'){ return ( <img className="icon" width={size} height={size} src={CartIcon} alt="Cart Icon"/>); }
  if(name === 'add-task'){ return ( <img className="icon" width={size} height={size} src={AddTaskIcon} alt="Add Task Icon"/>); }
  if(name === 'overview'){ return ( <img className="icon" width={size} height={size} src={OverviewIcon} alt="Overview Icon"/>); }
  if(name === 'tasks'){ return ( <img className="icon" width={size} height={size} src={TasksIcon} alt="Tasks Icon"/>); }
  if(name === 'microphone'){ return ( <img className="icon" width={size} height={size} src={MicrophoneIcon} alt="Microphone Icon"/>); }
};

export default Icon;
