import React from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from '@/components/Icon.js';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const PulsingIconWrapper = styled.div`
  display: inline-block;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

const PulsingIcon = ({ name }) => {
  return (
    <PulsingIconWrapper>
      <Icon name={name} size={20} />
    </PulsingIconWrapper>
  );
};

export default PulsingIcon;