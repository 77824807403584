import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postRequest } from "./../utils";

function usePrompt(user, when = true) {
  const navigate = useNavigate();

  useEffect(() => {
    const handler = async (_event) => {
      if (when) {
        await postRequest('/reset-context', JSON.stringify({ user_id: user.id }));
      }
    };

    window.addEventListener("beforeunload", handler);
    window.addEventListener("popstate", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
      window.removeEventListener("popstate", handler);
    };
  }, [user, navigate, when]);
}

export default usePrompt;

