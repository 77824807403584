import styled from 'styled-components';

const BG = styled.img`
position: absolute;
width: 100vw;
height: 100vh;
top: 0;
left: 0;
object-fit: cover;
`
const Frame = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  object-fit: cover;
  flex-direction: column;
`;

const Header = styled.div`
  position: absolute;
  width: calc(100% - 455px);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  top: 60px;
  left: 144px;
  z-index: 2;
  border-radius: 47px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  font-size: 16px;
  font-wight: 600;

  > div {
    display: flex;
    font-wight: 400;
    font-size: 13px;
    align-items: center;
  }

  img {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 8px;
  }
`;

const Call = styled.div`
  position: absolute;
  left: 144px;
  width: calc(100% - 455px);
  height: calc(100% - 270px);
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  top: 130px;
  overflow: hidden;
   .breakout-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(25% - 10px), 1fr));
    grid-template-rows: repeat(auto-fit, minmax(calc(25% - 10px), 1fr));
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 10px;
  }


  .breakout-view {
    display: grid;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .breakout-view.participants-1 {
    grid-template-columns: 1fr;
  }

  .breakout-view.participants-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .breakout-view.participants-3,
  .breakout-view.participants-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .breakout-view.participants-5,
  .breakout-view.participants-6 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .breakout-view.participants-7,
  .breakout-view.participants-8,
  .breakout-view.participants-9 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .breakout-view.participants-10,
  .breakout-view.participants-11,
  .breakout-view.participants-12 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .breakout-view.participants-13,
  .breakout-view.participants-14,
  .breakout-view.participants-15,
  .breakout-view.participants-16 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  .spotlight-view{
    width: 100%;
    min-width: calc(100% - 170px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gallery-view{
    min-width: 170px;
    width: 170px;
    max-width: 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tile-video{
    position: relative;
    &.small{
      video{
        object-fit: cover;
      }
    }
    &.large{
        height: 100%;
        width: 100%;
      }

      .video-placeholder{
        height: 100%;
      }
    }

   .video-placeholder{
      width: 100%;
      background: #37373D;
    }

    .tile-info{
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  video {
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%;
      object-fit: contain;
    }

`;

const SpotlightView = styled.div`
  `;

const GalleryView = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  video {
    min-width: 170px;
    max-width: 170px;
    height: 95px;
    object-fit: cover;
  }
`;

const ToolBar = styled.div`
  position: absolute;
  bottom: 60px;
  left: 144px;
  width: calc(100% - 455px);
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > div{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > div > button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.05);
    transition-duration:0.2s;
    &:hover{
        transition-duration:0.2s;
        background-color: rgba(255, 255, 255, 0.25);
    }
  }
`;

const Sidebar = styled.div`
  position: absolute;
  right: 24px;
  top: 60px;
  height: calc(100vh - 120px);
  width: 270px;
  display: flex;
  flex-direction: column;
`;

const Chat = styled.div`
  width: 100%;
  max-height: calc(100% - 225px);
  flex-grow: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  overflow: hidden;

  .input {
    position: fixed;
    bottom: 100px;
    width: 260px;
    display: flex;
    button{
      position: absolute;
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
      border: none;
      right: 30px;
    }
    input {
      color: white;
      width: calc(100% - 30px);
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 10px 8px;
      ::placeholder {
        color: white;
      }
    }
  }
  .chats {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 100;
    height: calc(100vh - 515px);
    overflow-y: scroll;

    >div{
      margin-bottom: 8px;
    }
    .message{
      margin: 0;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      padding: 8px 12px;
      color: white;
      text-wrap: normal;
      white-space: pre-wrap;
      word-break: break-word;

      &.mine{
        background-color: var(--green);
        color: var(--black);
      }
    }
  }
`;

const People = styled.div`
  width: 100%;
  height: 225px;
  background-color: rgba(255, 255, 255, 0.05);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  padding: 16px;
  ul{
    display: block;
    >div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
  `;

const Tabs = styled.div`
  display: flex;
  cursor: pointer;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  font-size: 14px;

  .unread-dot{
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--green);
    right: -6px;
    top: -3px;
  }

  > div {
    margin-right: 11px;
    padding-bottom: 14px;
    position: relative;
    &.active {
      border-bottom: 2px solid var(--green);
      font-weight: 600;
    }
  }
`;

const EmojiReaction = styled.div`
  position: absolute;
  font-size: 24px;
  animation: floatUp 2s ease-out forwards;
  z-index: 8;
  opacity: 0;

  @keyframes floatUp {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100px);
      opacity: 0;
    }
  }
`;


export {
  BG,
  Frame,
  Header,
  Call,
  SpotlightView,
  GalleryView,
  ToolBar,
  Sidebar,
  Chat,
  People,
  Tabs,
  EmojiReaction,
}

