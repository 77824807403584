import React from 'react';
import styled, { css } from 'styled-components';

const ProgressBarContainer = styled.div`
margin: 0 16px;
`;

const Progress = styled.div`
  flex: 1;
  min-height: 8px;
  height: 8px;
  border-radius: 4px;
  background: #323534;
  overflow: hidden;
  position: relative;
  min-width: 100px;

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: ${({ progress }) => progress}%;
    background: ${({ status }) => {
      if (status === true) return 'green';
      if (status === false) return 'red';
      return '#6A53F5';
    }};
    transition: width 0.3s ease;
  }
`;

const ProgressText = styled.div`
  margin-top: 10px;
  font-size: 11px;
  white-space: nowrap;
`;

const ProgressBar = ({ status, progress, max, label='' }) => {
  const percentage = (progress / max) * 100;

  return (
    <ProgressBarContainer>
      <Progress progress={percentage} status={status} />
      <ProgressText>{`${label}${Math.round(progress)} of ${label}${max}`}</ProgressText>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
