import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { debounce } from 'lodash';
import Icon from './Icon';
import { Portal } from './../utils';

const Video = styled.div`
cursor: pointer;
position relative;
.preview {
  margin: 24px 0;
  border-radius: 8px;
}

img{
  position: absolute;
  left: calc(50% - 25px);
  top: 225px;
  width: 50px;
  height: 50px;
}
img.preview{
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: calc(50% - 25px);
}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Modal = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
z-index: 99999;
background-color: rgba(0,0,0,0.85);
display: flex;
align-items: center;
justify-content: center;
video, img{
  width: 90vw;
  max-width: 1080px;
  z-index: 99999;
  pointer-events: all;
  height: auto;
  max-height: 80vh;
  border-radius: 16px;
  object-fit: cover;
}
`;

const Content = ({ content }) => {

  const [viewing, setViewing] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    if (viewing) {
      contentRef.current.focus();
    }
  }, [viewing]);

  const debouncedClick = debounce(() => { setViewing(true); }, 150);

  if(content && content.type && content.asset) {

    return (
      <div className="content">
        {viewing && (
          <Portal>
            <Modal onClick={() => { setViewing(false); }}>
              {viewing && content.type === 'video' && (
                <video
                  ref={contentRef}
                  onClick={(e) => { e.stopPropagation(); }}
                  src={viewing ? content.asset : ''}
                  controls={'true'}
                  autoPlay={true}
                >
                  <source src={content.asset} type="video/mp4" />
                </video>
              )}
              {viewing && content.type === 'img' && (
                <img
                  ref={contentRef}
                  onClick={(e) => { e.stopPropagation(); }}
                  src={viewing ? content.asset : ''}
                />
              )}
            </Modal>
          </Portal>
        )}

        <Container>
          {content.type === 'slides' && <p onClick={() => { window.openDocViewer(); }}>slide preview goes here</p>}

          {content.type === 'text' && (
            <div onClick={() => { window.openDocViewer(); }}>
              <ReactMarkdown>{content.content}</ReactMarkdown>
              <a href="#">learn more</a>
            </div>
          )}

          {content.type === 'video' && (
            <Video onClick={debouncedClick}>
              <img className="preview" src={content.preview} alt="preview" />
              <Icon name="play" size={50} color="var(--white)" className="play" />
            </Video>
          )}

          {content.type === 'img' && (
            <Video onClick={debouncedClick}>
              <img className="preview" src={content.asset} alt="preview" />
            </Video>
          )}
        </Container>
      </div>
    );
  }
};

export default Content;

