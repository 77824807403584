import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { postRequest, uploadRequest, Portal  } from './../utils';
import { FileUpload } from './../views/styles.js';

const Graph = styled.div`
  background-color: var(--bg);
  height: 300px;
  box-sizing: content-box;
  padding: 32px;
  border-radius: 32px;
  border: 1px solid var(--grey);

  h1{
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
  }
`
const Backdrop = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
`;

const FormContainer = styled.form`
  background-color: var(--bg);
  position: fixed;
  min-height: 300px;
  max-height: 95vh;
  overflow-y: scroll;
  width: 500px;
  left: calc(50% - 200px);
  padding: 28px;
  border-radius: 16px;
  color: white;
  z-index: 10;
  top: 50vh;
  transform: translateY(-50%);
  h2{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 32px;
  }
 `;

const Label = styled.label`
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
`;

const InputField = styled.input`
  width: 100%;
  padding: 16px 10px;
  margin-bottom: 20px;
  border-radius: 16px;
  border: 1px solid var(--grey);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  resize: none;
  height: 100px;
  border-radius: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  border: none;
  background-color: var(--teal);
  color: #1c1c1e;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
  &:disabled {
    opacity: 0.25;
  }
`;


const Button = styled.button`
  padding: 15px;
  border-radius: 32px;
  border: none;
  background-color: var(--teal);
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--hilight);
  }
`;

const CloseButton = styled.button`
  float: right;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
`;

const Toast = styled.div`
  position: fixed;
  bottom: 100px;
  right: 50%;
  z-index: 999;
  background-color: var(--black);
  padding: 24px 32px;
  border-radius: 16px;
`

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MultiLineGraph = ({revenue, sales, loadTeamData}) => {

  const cumulativeSales = [];
  sales.reduce((acc, sale) => {
    const cumulativeAmount = acc + sale.amount;
    cumulativeSales.push(cumulativeAmount);
    return cumulativeAmount;
  }, 0);

  const sales_data = [...sales.map(x => new Date(x.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }))]
  if(sales_data.length < 4){
    for(let i = 0; i < 4 - sales_data.length; i++){
      sales_data.push('')
    }
  }

  const data = {
    labels: sales_data,
    datasets: [
      {
        label: 'Sales',
        data: cumulativeSales,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        pointBorderColor: 'rgba(75, 192, 192, 0.5)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(75, 192, 192)',
        pointHoverBorderColor: 'rgb(220,220,220)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        tension: 0.4,
      },
    ],
  };

  const maxRevenue = Math.ceil(Math.max(...cumulativeSales) / 100) * 100;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: maxRevenue,
        ticks: {
          callback: function(value, index, values) {
            return '$' + value;
          },
          color: "white",
        },
        grid: {
          display: false,
          color: "rgba(255, 255, 255, 0)",
          borderColor: "rgba(255, 255, 255, 0)"
        },
      },
      x: {
        grid: {
          color: "rgba(255, 255, 255, 0.15)",
          borderColor: "rgba(255, 255, 255, 0.15)",
          borderDash: [1, 1]
        },
        ticks: {
          color: "#a1a1a1",
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0
      }
    }
  };

  const [saleAmount, setSaleAmount] = useState('');
  const [learnings, setLearnings] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState(false);

  const [file, setFile] = useState(null);

  const handleSaleAmountChange = (e) => {
    setSaleAmount(e.target.value);
  };

  const handleLearningsChange = (e) => {
    setLearnings(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file.size > 25 * 1024 * 1024) {
        alert('File size exceeds 25MB. Please choose a smaller file.');
        return;
    }

    const formData = new FormData();
    formData.append('file', file);
    const { id, filename } = await uploadRequest('/upload', formData);
    setFile({id: id, filename: filename})
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await postRequest('/add_sale', JSON.stringify({
      amount: saleAmount,
      learnings: learnings,
      receipt: file.id,
      team: window.user.team
    }));
    setSubmitting(false);
    setToast(true);
    setTimeout(() => setToast(false), 3000);
    loadTeamData()
    window.confetti.add()
    setSaleAmount('')
    setLearnings('')
    setFile(null)
  };

  useEffect(() => {
    if(!submitting) {
      setFile(null)
    }
  }, [submitting]);

  return <Graph >
    { toast &&
      <Toast>
        <p>Sale submitted!</p>
      </Toast>
    }

    { submitting &&
    (
      <Portal>
        <Backdrop onClick={() => setSubmitting(false)}/>
        <FormContainer onSubmit={handleSubmit}>
          <h2>Submit a sale</h2>

          <Label htmlFor="saleAmount">Sale amount</Label>
          <InputField
            type="number"
            id="saleAmount"
            value={saleAmount}
            onChange={handleSaleAmountChange}
            placeholder="$"
          />

          <Label htmlFor="learnings">What did you learn from this sale?</Label>
          <TextArea
            id="learnings"
            value={learnings}
            onChange={handleLearningsChange}
            placeholder="Write something..."
          />

          {file === null &&
              <>
                <Label htmlFor="fileUpload">Upload proof of sale</Label>
                <FileUpload>
                  <input
                    type="file"
                    id="fileUpload"
                    accept="image/*,video/*,audio/*,text/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.html,.xml,.csv,.md,.rtf,.txt"
                    onChange={handleFileChange}
                  />

                  <img src="https://storage.googleapis.com/prequel-public/Frame%20446.png" alt="upload"/>
                  <p>Drag and drop file here or click to upload</p>

                  <span>25mb limit</span>
                </FileUpload>
              </>
          }

          {file !== null &&  <FileUpload> <p>File uploaded: {file.filename} </p> </FileUpload> }

          <SubmitButton type="submit" disabled={file === null || saleAmount === '' || learnings.trim() === ''}> Submit sale</SubmitButton>
        </FormContainer>
      </Portal>
    )}

    <h1> ${revenue} <Button onClick={() => setSubmitting(true)}> Submit a Sale</Button> </h1>
    <div style={{height: "200px"}}>
      <Line data={data} options={options} />
    </div>
  </Graph>;
};

export default MultiLineGraph;
